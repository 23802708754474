import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import footerStyles from './footer.module.css'
import { user$ } from '../../services/auth'

const Footer = () => {
  const [state, setState] = useState({
    user: {}
  })

  useEffect(() => {
    const subscription = user$.subscribe(authUser => {
      setState({user: authUser})
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [state.user])

  return <div className={footerStyles.footer}>
    <p>Don't see your kite school? <a href="mailto:info@caniteachmyselfkiteboarding.com">Email us</a> the details and we will add it to the list.</p>
    {state.user && state.user.email ? '' : <><Link to="/account/login">Sign in to update or promote your school</Link><br /></>}
    <Link to="/credits" style={{ fontSize: 'var(--s-2)' }}>Click here for details about the images used on this website.</Link><br />
    <Link to="/privacy-policy" style={{ fontSize: 'var(--s-2)' }}>Privacy Policy.</Link>
    <p>&#169; Ingraco 2020</p>
  </div>
}

export default Footer
