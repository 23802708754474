import React from 'react'

import './layout.css'
import Footer from './Footer/Footer'
import { bindToUserSession } from '../services/auth'

export default function Layout({ children }) {
  bindToUserSession()

  return <>
    <React.Fragment>{children}</React.Fragment>
    <Footer></Footer>
  </>
}
